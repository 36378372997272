<template>
  <div style="min-height: 100vh;">
    <navbar @asideShow="asideFlagFun"></navbar>
    <div class="main">
      <div class="back" @click="back">
        <van-image width="9px" height="15px" :src="require('@/assets/cls/arrow-l.png')" />
        <span>{{$t("myShare.myShare")}}</span>
      </div>
        <div class="list-box">
        <div class="title">
          <div>{{$t("myShare.address")}}</div>
          <div>{{$t("myShare.time")}}</div>
        </div>
        <van-list
          v-model="loading"
          @load="onLoad"
          :finished="finished"
          class="list"
          :finished-text="$t('activeRecord.NoMore')"
          :immediate-check="false"
          :offset="100"
        >
          <div class="item" v-for="(item, index) in list" :key="index">
            <div>{{item.address.slice(0,6)}}****{{item.address.slice(-7)}}</div>
            <div>{{item.created_at}}</div>
          </div>
        </van-list>
      </div>
    </div>
    <asidePopup v-if="asideFlag" @close="noShow" :shows='asideFlag'></asidePopup>
  </div>
</template>
<script>
import asidePopup from "@/components/common/asidePopup";
export default {
  components: { asidePopup },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
      asideFlag: false,
    };
  },
  methods: {
    noShow(){
      this.asideFlag = false
    },
    asideFlagFun(){
      this.asideFlag = true
    },
    asideFlagShow(e) {
      this.asideFlag = e;
    },
    //上拉加载事件
    onLoad() {
      this.page++; //每次加载页数加1
      this.getList(); //调用请求接口数据的方法
    },
    back() {
      this.$router.back();
    },
    getList() {
      const _self = this;

      this.$axios
        .get(this.$api.invite_list, { pageSize: 20, page: this.page })
        .then(res => {
          const rows = res.data.list;
          if (rows == null || rows.length === 0) {
            // 加载结束
            _self.finished = true;
            _self.list = [];
            return;
          }
          if (rows.length < 20) {
            // 最后一页不足10条的情况
            _self.finished = true;
          }
          //第一页时，数据赋值
          if (_self.page == 1) {
            _self.list = rows;
          } else {
            //数据拼接
            _self.list = _self.list.concat(rows);
          }
       
        })
        .finally(() => {
          _self.loading = false;
        });
    }
  },
  created() {
    this.getList();
  }
};
</script>
<style lang="scss" scoped>
.main {
  padding: 13px 15px;
  box-sizing: border-box;
  height: calc(100vh - 62px);
  .back {
    margin: 0 0 24px;
    display: flex;
    align-items: center;
    span {
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      margin-left: 6px;
    }
  }
  .lists{
    padding-bottom: 7px;
    border-radius:15px;
    border-bottom: 1px solid #000;
    background: #7857fe;
  }
  .list-box {
    height: calc(80vh - 39px);
    background: #ffffff;
    // box-shadow: 0px 0px 4px 0px rgba(180, 180, 180, 0.5);
    border: 1px solid #000;
    border-radius: 15px;
    padding: 0 10px;
    .title {
      font-size: 10px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      height: 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(234, 234, 234, 0.8);
    }
    .list {
      height: calc(100% - 36px);
      overflow-y: auto;
      .item {
        height: 47px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(234, 234, 234, 0.8);
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
</style>